:root {
    --primary-color: #1e3a8a; 
    --secondary-color: #cccccc; 
    --background-color: #ffffff; 
    --hover-color: #3b5998; 
}

body {
    background-color: var(--background-color);
    color: var(--primary-color);
}
