.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: var(--background-color);
    padding: 20px;
}

.login-header {
    text-align: center;
    margin-bottom: 20px;
}

.logo {
    width: 200px;
        /* Ajuste para dispositivos móveis */
    margin-bottom: 10px;
}

.login-title {
    color: var(--primary-color);
    font-size: 1.5rem;
        /* Ajuste do tamanho do texto */
}

.login-form {
    background-color: var(--background-color);
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    transition: transform 0.3s ease-in-out;
}

.login-form:hover {
    transform: scale(1.02);
}

.form-group {
    margin-bottom: 15px;
    position: relative;
}

.input-icon {
    display: flex;
    align-items: center;
    border: 1px solid var(--secondary-color);
    border-radius: 8px;
    padding: 8px;
    background-color: var(--background-color);
    transition: box-shadow 0.3s ease-in-out;
    justify-content: space-between; 
}

.input-icon i {
    color: var(--primary-color);
    transition: color 0.3s ease-in-out;
    cursor: pointer; 
}

.input-icon:hover i {
    color: var(--hover-color);
}

input {
    border: none;
    outline: none;
    width: 100%;
    background-color: transparent;
    padding: 8px;
    color: var(--primary-color);
    font-size: 1rem;
}

input::placeholder {
    color: var(--secondary-color);
}

.login-btn {
    background-color: var(--primary-color);
    color: var(--background-color);
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s ease-in-out; 
}

.login-btn:hover {
    background-color: var(--hover-color); 
}

/* Responsividade */
@media (max-width: 768px) {
    .login-container {
        padding: 15px;
    }

    .login-form {
        padding: 15px;
        max-width: 90%;
    }
.login-title {
    font-size: 1.3rem;
    /* Ajusta o título para telas menores */
}

.logo {
    width: 150px;
    /* Reduz o tamanho do logo */
}
}

@media (max-width: 480px) {
    .login-container {
            padding: 10px;
        }
    .login-form {
        padding: 10px;
        max-width: 100%;
    }

        .login-title {
            font-size: 1.2rem;
            /* Ajuste ainda menor do título */
        }
    
        .logo {
            width: 120px;
            /* Ajuste final do tamanho do logo */
        }
    .login-btn {
        padding: 8px 16px;
    }
.form-group {
    margin-bottom: 10px;
}

input {
    padding: 6px;
    font-size: 0.9rem;
    /* Ajuste do tamanho da fonte */
}
}
