.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f4f4f4;
    padding: 20px;
}

.welcome-card {
    background-color: white;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 400px;
    width: 100%;
    margin-bottom: 20px;
    position: relative;
}

.welcome-card h1 {
    margin-bottom: 10px;
    color: #1e3a8a;
    font-size: 1.5rem;
}

.welcome-card p {
    margin-bottom: 20px;
    color: #555;
    font-size: 1rem;
}

.logout-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 8px 16px;
    background-color: #ff4d4d;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.875rem;
}

.logout-btn:hover {
    background-color: #ff3333;
}

.dashboard-carousel {
    display: flex;
    flex-wrap: wrap;
        /* Permite que os itens fiquem em várias linhas */
        gap: 20px;
        justify-content: center;
        /* Mantém os itens centralizados */
    padding: 20px;
    width: 100%;
}

.dashboard-card {
    background-color: #1e3a8a;
    color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 150px;
    text-align: center;
    transition: transform 0.3s ease;
    cursor: pointer;
}

.dashboard-card:hover {
    transform: scale(1.1);
}

.dashboard-icon {
    font-size: 40px;
    margin-bottom: 10px;
}

.fade-in {
    opacity: 1;
    transition: opacity 0.5s ease-in;
}

.fade-out {
    opacity: 0;
    transition: opacity 0.5s ease-out;
}

/* Responsividade */
@media (max-width: 768px) {
    .home-container {
        padding: 15px;
        height: auto;
        /* Permite ajuste para telas menores */
    }

    .welcome-card {
        padding: 20px;
        max-width: 90%;
    }

    .welcome-card h1 {
        font-size: 1.3rem;
    }

    .welcome-card p {
        font-size: 0.9rem;
    }

    .dashboard-carousel {
        gap: 15px;
    }

    .dashboard-card {
        width: 130px;
        padding: 15px;
    }

    .dashboard-icon {
        font-size: 35px;
    }
}

@media (max-width: 480px) {
    .home-container {
        padding: 10px;
        height: auto;
        /* Ajusta a altura para telas pequenas */
    }

    .welcome-card {
        padding: 15px;
        max-width: 100%;
    }

    .welcome-card h1 {
        font-size: 1.2rem;
    }

    .welcome-card p {
        font-size: 0.8rem;
    }

    .dashboard-carousel {
        gap: 10px;
    }

    .dashboard-card {
        width: 100px;
        /* Ajuste final do card para caber mais itens na tela */
        padding: 10px;
    }
        .dashboard-icon {
        font-size: 30px;
        }
        
        .logout-btn {
            padding: 6px 12px;
            font-size: 0.8rem;
        }
}
