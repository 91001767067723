.bi-detail-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
    padding: 20px;
    background-color: #f4f4f4;
}

.bi-detail-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 1200px;
    margin-bottom: 20px;
}

.back-button {
    margin-right: 20px;
    padding: 10px 20px;
    background-color: #1e3a8a;
    color: white;
    border: none;
    border-radius: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.back-button:hover {
    background-color: #3b5998;
}

.back-icon {
    font-size: 16px;
}

.bi-iframe-container {
    position: relative;  /* Define o contexto para o overlay */
    width: 100%;
    max-width: 1200px;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
}

.bi-iframe {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 12px;
}

.bi-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40px;  /* Ajuste a altura conforme necessário */
    background-color: white;  /* Cor da barra que cobre */
    z-index: 10;  /* Garante que fique sobre o iframe */
}
